@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1743410404040/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1743410404040/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1743410404040/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-arrow-down-bold:before { content: "\EA02" }
.icon-arrow-down:before { content: "\EA03" }
.icon-arrow-dropdown:before { content: "\EA04" }
.icon-arrow-left:before { content: "\EA05" }
.icon-arrow-right:before { content: "\EA06" }
.icon-arrow-up:before { content: "\EA07" }
.icon-attractions:before { content: "\EA08" }
.icon-bicycle:before { content: "\EA09" }
.icon-bin:before { content: "\EA0A" }
.icon-cabin-console:before { content: "\EA0B" }
.icon-cabin:before { content: "\EA0C" }
.icon-cable-car:before { content: "\EA0D" }
.icon-calendar:before { content: "\EA0E" }
.icon-card-holiday:before { content: "\EA0F" }
.icon-card-lesachtal:before { content: "\EA10" }
.icon-card-weissensee:before { content: "\EA11" }
.icon-card-winter:before { content: "\EA12" }
.icon-cart:before { content: "\EA13" }
.icon-check:before { content: "\EA14" }
.icon-close:before { content: "\EA15" }
.icon-contact:before { content: "\EA16" }
.icon-controls:before { content: "\EA17" }
.icon-conveyor:before { content: "\EA18" }
.icon-download:before { content: "\EA19" }
.icon-drag:before { content: "\EA1A" }
.icon-draganddrop:before { content: "\EA1B" }
.icon-draglift:before { content: "\EA1C" }
.icon-elements:before { content: "\EA1D" }
.icon-email:before { content: "\EA1E" }
.icon-facebook:before { content: "\EA1F" }
.icon-facilities:before { content: "\EA20" }
.icon-food:before { content: "\EA21" }
.icon-gift:before { content: "\EA22" }
.icon-ice-water:before { content: "\EA23" }
.icon-info:before { content: "\EA24" }
.icon-instagram:before { content: "\EA25" }
.icon-keycard:before { content: "\EA26" }
.icon-link:before { content: "\EA27" }
.icon-linkedin:before { content: "\EA28" }
.icon-list:before { content: "\EA29" }
.icon-location:before { content: "\EA2A" }
.icon-mail:before { content: "\EA2B" }
.icon-minus:before { content: "\EA2C" }
.icon-mountains:before { content: "\EA2D" }
.icon-phone:before { content: "\EA2E" }
.icon-pinterest:before { content: "\EA2F" }
.icon-play:before { content: "\EA30" }
.icon-plus-thin:before { content: "\EA31" }
.icon-plus:before { content: "\EA32" }
.icon-poi:before { content: "\EA33" }
.icon-print:before { content: "\EA34" }
.icon-profile-check:before { content: "\EA35" }
.icon-profile-skiing:before { content: "\EA36" }
.icon-profile:before { content: "\EA37" }
.icon-search:before { content: "\EA38" }
.icon-settings:before { content: "\EA39" }
.icon-skiing:before { content: "\EA3A" }
.icon-slopelines:before { content: "\EA3B" }
.icon-slopesnowheight:before { content: "\EA3C" }
.icon-snail:before { content: "\EA3D" }
.icon-snow:before { content: "\EA3E" }
.icon-ticket:before { content: "\EA3F" }
.icon-tickets:before { content: "\EA40" }
.icon-time:before { content: "\EA41" }
.icon-tip:before { content: "\EA42" }
.icon-triangle-small:before { content: "\EA43" }
.icon-triangle:before { content: "\EA44" }
.icon-twitter:before { content: "\EA45" }
.icon-user:before { content: "\EA46" }
.icon-video:before { content: "\EA47" }
.icon-voucher:before { content: "\EA48" }
.icon-wallet:before { content: "\EA49" }
.icon-warning:before { content: "\EA4A" }
.icon-waves:before { content: "\EA4B" }
.icon-weather-a:before { content: "\EA4C" }
.icon-weather-b:before { content: "\EA4D" }
.icon-weather-c:before { content: "\EA4E" }
.icon-weather-d:before { content: "\EA4F" }
.icon-weather-e:before { content: "\EA50" }
.icon-weather-f:before { content: "\EA51" }
.icon-weather-g:before { content: "\EA52" }
.icon-weather-h:before { content: "\EA53" }
.icon-weather-i:before { content: "\EA54" }
.icon-weather-j:before { content: "\EA55" }
.icon-weather-k:before { content: "\EA56" }
.icon-weather-l:before { content: "\EA57" }
.icon-weather-m:before { content: "\EA58" }
.icon-weather-n:before { content: "\EA59" }
.icon-weather-o:before { content: "\EA5A" }
.icon-weather-p:before { content: "\EA5B" }
.icon-weather-q:before { content: "\EA5C" }
.icon-weather-r:before { content: "\EA5D" }
.icon-weather-s:before { content: "\EA5E" }
.icon-weather-t:before { content: "\EA5F" }
.icon-weather-u:before { content: "\EA60" }
.icon-weather-v:before { content: "\EA61" }
.icon-weather-w:before { content: "\EA62" }
.icon-weather-x:before { content: "\EA63" }
.icon-weather-y:before { content: "\EA64" }
.icon-weather-z:before { content: "\EA65" }
.icon-weather:before { content: "\EA66" }
.icon-webcam-table:before { content: "\EA67" }
.icon-webcam-video:before { content: "\EA68" }
.icon-webcam:before { content: "\EA69" }
.icon-whatsapp:before { content: "\EA6A" }
.icon-wlan:before { content: "\EA6B" }
.icon-youtube:before { content: "\EA6C" }


:root {
--icon-addthis: "\EA01";
    --icon-arrow-down-bold: "\EA02";
    --icon-arrow-down: "\EA03";
    --icon-arrow-dropdown: "\EA04";
    --icon-arrow-left: "\EA05";
    --icon-arrow-right: "\EA06";
    --icon-arrow-up: "\EA07";
    --icon-attractions: "\EA08";
    --icon-bicycle: "\EA09";
    --icon-bin: "\EA0A";
    --icon-cabin-console: "\EA0B";
    --icon-cabin: "\EA0C";
    --icon-cable-car: "\EA0D";
    --icon-calendar: "\EA0E";
    --icon-card-holiday: "\EA0F";
    --icon-card-lesachtal: "\EA10";
    --icon-card-weissensee: "\EA11";
    --icon-card-winter: "\EA12";
    --icon-cart: "\EA13";
    --icon-check: "\EA14";
    --icon-close: "\EA15";
    --icon-contact: "\EA16";
    --icon-controls: "\EA17";
    --icon-conveyor: "\EA18";
    --icon-download: "\EA19";
    --icon-drag: "\EA1A";
    --icon-draganddrop: "\EA1B";
    --icon-draglift: "\EA1C";
    --icon-elements: "\EA1D";
    --icon-email: "\EA1E";
    --icon-facebook: "\EA1F";
    --icon-facilities: "\EA20";
    --icon-food: "\EA21";
    --icon-gift: "\EA22";
    --icon-ice-water: "\EA23";
    --icon-info: "\EA24";
    --icon-instagram: "\EA25";
    --icon-keycard: "\EA26";
    --icon-link: "\EA27";
    --icon-linkedin: "\EA28";
    --icon-list: "\EA29";
    --icon-location: "\EA2A";
    --icon-mail: "\EA2B";
    --icon-minus: "\EA2C";
    --icon-mountains: "\EA2D";
    --icon-phone: "\EA2E";
    --icon-pinterest: "\EA2F";
    --icon-play: "\EA30";
    --icon-plus-thin: "\EA31";
    --icon-plus: "\EA32";
    --icon-poi: "\EA33";
    --icon-print: "\EA34";
    --icon-profile-check: "\EA35";
    --icon-profile-skiing: "\EA36";
    --icon-profile: "\EA37";
    --icon-search: "\EA38";
    --icon-settings: "\EA39";
    --icon-skiing: "\EA3A";
    --icon-slopelines: "\EA3B";
    --icon-slopesnowheight: "\EA3C";
    --icon-snail: "\EA3D";
    --icon-snow: "\EA3E";
    --icon-ticket: "\EA3F";
    --icon-tickets: "\EA40";
    --icon-time: "\EA41";
    --icon-tip: "\EA42";
    --icon-triangle-small: "\EA43";
    --icon-triangle: "\EA44";
    --icon-twitter: "\EA45";
    --icon-user: "\EA46";
    --icon-video: "\EA47";
    --icon-voucher: "\EA48";
    --icon-wallet: "\EA49";
    --icon-warning: "\EA4A";
    --icon-waves: "\EA4B";
    --icon-weather-a: "\EA4C";
    --icon-weather-b: "\EA4D";
    --icon-weather-c: "\EA4E";
    --icon-weather-d: "\EA4F";
    --icon-weather-e: "\EA50";
    --icon-weather-f: "\EA51";
    --icon-weather-g: "\EA52";
    --icon-weather-h: "\EA53";
    --icon-weather-i: "\EA54";
    --icon-weather-j: "\EA55";
    --icon-weather-k: "\EA56";
    --icon-weather-l: "\EA57";
    --icon-weather-m: "\EA58";
    --icon-weather-n: "\EA59";
    --icon-weather-o: "\EA5A";
    --icon-weather-p: "\EA5B";
    --icon-weather-q: "\EA5C";
    --icon-weather-r: "\EA5D";
    --icon-weather-s: "\EA5E";
    --icon-weather-t: "\EA5F";
    --icon-weather-u: "\EA60";
    --icon-weather-v: "\EA61";
    --icon-weather-w: "\EA62";
    --icon-weather-x: "\EA63";
    --icon-weather-y: "\EA64";
    --icon-weather-z: "\EA65";
    --icon-weather: "\EA66";
    --icon-webcam-table: "\EA67";
    --icon-webcam-video: "\EA68";
    --icon-webcam: "\EA69";
    --icon-whatsapp: "\EA6A";
    --icon-wlan: "\EA6B";
    --icon-youtube: "\EA6C";
    
}