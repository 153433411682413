.icon-teaser{
    background-color: var(--color-white);
    color: var(--color-icon-teaser-text);
}
.icon-teaser:before {
    border-top-color: var(--color-light);
}
.icon-teaser__body {
    color: var(--color-icon-teaser-text);
}
.icon-teaser__title{
    margin-top: calc(20rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(20rem/16);
    }
    @media screen and (min-width: 1400px){
        margin-top: calc(20rem/16);
    }
}
.icon-teaser__icon{
    color: var(--color-icon-teaser-text);
}